function activityRigger() {
  const self = this;
  const select = {
    node: document.querySelector("[data-rigger='activity']"),
    activityFrame: document.querySelector("[data-rigger='activity'].activity-frame"),
    activityIFrame: document.querySelector("[data-rigger='activity'].activity-frame iframe"),
    fullScreenToggler: document.querySelector("[data-rigger='activity'] #fullscreen-toggle")
  };
  let node = null;

  const rig = () => {
    rigFullscreenToggler();
    document.addEventListener("fullscreenchange", () => {
      select.activityFrame.dataset.fullscreen = document.fullscreenElement !== null ? "yes" : "no";
    });
  }

  const toggleFullscreen = () => {
    if(document.fullscreenElement === null) {
      select.activityFrame.requestFullscreen();
    }else{
      document.exitFullscreen();
    }
  }

  const rigFullscreenToggler = () => {
    select.fullScreenToggler.addEventListener("click", (e) => {
      toggleFullscreen();
    })
  }

  const init = () => {
    if(select.node === null) return;

    rig();
  }

  init();

  return self;
}

(function() {
  document.addEventListener("DOMContentLoaded", function() {
    window.corujinha = window.corujinha || {};
    window.corujinha.riggers = window.corujinha.riggers || {};
    window.corujinha.riggers.activity = new activityRigger();
  });
}());
